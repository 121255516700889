<template>
  <v-container class="fill-height" >
    <v-row dense align="center">
      <v-col cols="8" offset="2">
        <v-row class="text-center"   >
          <v-col cols="12">
            <h3>{{mode}}</h3>
          </v-col>
          <v-col cols="12" v-if="mode==='Loading'">
            <v-progress-linear color="color1" v-bind:indeterminate="true"></v-progress-linear>
          </v-col>
          <v-col cols="12">
            <v-alert
              color="success"
              icon="far fa-check_circle"
              :value="mode==='Confirmed'"
              transition="scale-transition"
              >
              Thank you! Your email is now confirmed.
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-alert
              color="error"
              icon="fas fa-exclamation-triangle"
              :value="mode==='Error'"
              transition="scale-transition"
              >
              {{errorText}}
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vbl from '@/VBL/endpoints'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      mode: 'Loading',
      errorText: ''
    }
  },
  computed: {
    ...mapGetters(['user']),
    emailId () {
      return this.$route.query.e
    },
    token () {
      return this.$route.query.t
    }
  },
  methods: {
    confirmEmail () {
      this.axios.post(vbl.email.confirm(this.emailId), { access_token: this.token })
        .then((response) => {
          if (response.data) {
            this.mode = 'Confirmed'
          } else {
            this.errorText = 'Invalid Token'
            this.mode = 'Error'
          }
        })
        .catch((error) => {
          console.log(error.response.data)
          this.errorText = 'We apologize but there was a communication error. Please try refreashing the page'
          this.mode = 'Error'
        })
        .finally(() => {
          if (this.user) {
            this.gotoMe()
          }
        })
    },
    gotoMe () {
      this.$router.push({ name: 'me', query: { message: this.mode === 'Confirmed' ? 'emailConfirmed' : 'emailConfirmFailed' } })
    }
  },
  watch: {
    user: function (val) {
      if (val && this.mode !== 'Loading') {
        this.gotoMe()
      }
    }
  },
  created () {
    this.confirmEmail()
  }
}
</script>

<style>

</style>
